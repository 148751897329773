<template>
  <div>
    <NuxtLayout>
      <CommonUiGrid />
      {{ error }}
    </NuxtLayout>
  </div>
</template>

<script setup>
const props = defineProps(['error'])

console.log('error:', toRaw(props.error))

if (
     props.error.statusCode === '404'
  || props.error.message.startsWith('Cannot read properties')
) {
  clearError({ redirect: '/404' })
} else {
  clearError({ redirect: '/404' })
}

</script>
