<template>
  <div id="sysGrid" class="grid app-container rt-container">
    <div class="rt-col-12">
      <div class="row">
        <div class="rt-col-1 rt-col-td-1 rt-col-md-1 demo-col test"></div>
        <div class="rt-col-1 rt-col-td-1 rt-col-md-1 demo-col"></div>
        <div class="rt-col-1 rt-col-td-1 rt-col-md-1 demo-col"></div>
        <div class="rt-col-1 rt-col-td-1 demo-col md-d-none"></div>
        <div class="rt-col-1 rt-col-td-1 demo-col md-d-none"></div>
        <div class="rt-col-1 rt-col-td-1 demo-col md-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
        <div class="rt-col-1 demo-col md-d-none td-d-none"></div>
      </div>
    </div>
  </div>
</template>



<style>
.grid {
  pointer-events: none;
  height: 0;
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 0;
  bottom: 100vh;
  left: 0;
  right: 0;
  z-index: 200;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: height, bottom, opacity, left;
  -moz-transition-property: height, bottom, opacity, left;
  -o-transition-property: height, bottom, opacity, left;
  -ms-transition-property: height, bottom, opacity, left;
  transition-property: height, bottom, opacity, left;
}
.grid--active {
  bottom: 0;
  opacity: 1;
  height: 100vh;
}
@media (max-width: 1024px) {
  .grid {
    left: 0px;
    right: 0px;
    position: fixed;
  }
}
.grid .row {
  height: 100vh;
}
.grid .demo-col:before {
  content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(119,0,255,0.04);
  border-left: 1px solid rgba(255,79,18,0.3);
  border-right: 1px solid rgba(255,79,18,0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
}
.grid .demo-col:after {content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid rgba(188,16,75,0.35);
  border-right: 1px solid rgba(188,16,75,0.35);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  right: 30px;
}
</style>

<script setup>

onMounted(() => {
  /* Подключи данный сэмпл чтобы смотреть сетку по нажатию Ctrl+G */
  window.addEventListener('keydown',e=>(e.keyCode||e.which)===71?document.getElementById('sysGrid').classList.toggle('grid--active'):0)
})
</script>

